<template>
  <a-card class="card rent" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getMmsPage"
      :deleteFun="deleteMmsInfo"
      :createFun="createMmsInfo"
      :updateFun="updateMmsInfo">
    </base-page>
  </a-card>
</template>
<script>
import moment from 'moment';
import { BasePage } from '@/components'
import { getMmsPage, getMmsTenantOptions, createMmsInfo, updateMmsInfo, deleteMmsInfo } from '@/api/mms'
import { getSystemInfoOption } from '@/api/system'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      tenantOps: [],
      queryFields: [
        { label: '租户简称', name: 'tenant_id', fieldType: 'select', queryType: '', options: [] }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '租户简称',
          dataIndex: 'short_code'
        },
        {
          title: '租赁地址',
          dataIndex: 'address'
        },
        {
          title: '合同编号',
          dataIndex: 'serial_num'
        },
        {
          title: '租赁起始时间',
          dataIndex: 'start',
          width: 120,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '租赁结束日期',
          dataIndex: 'end',
          width: 120,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        tenant: [
          { required: true, message: '请选择租户简称', trigger: 'blur' }
        ]
      },
      modelTitle: '租赁登记',
      formFields: [
        {
          label: '租户简称', name: 'tenant', type: 'select', options: []
        },
        {
          label: '', name: 'short_code', type: 'select_name', ref: 'tenant'
        },
        {
          label: '租赁地址', name: 'address', type: 'default'
        },
        {
          label: '租赁起始时间', name: 'start', type: 'date'
        },
        {
          label: '租赁结束日期', name: 'end', type: 'date'
        },
        {
          label: '提前提醒天数', name: 'remind_days_before', type: 'number'
        },
        {
          label: '租赁到期提醒人', name: 'reminders', type: 'select', mode: 'multiple', options: [], func: getSystemInfoOption, params: 'user'
        },
        {
          label: '合同编号', name: 'serial_num', type: 'default'
        },
        {
          label: '租赁备注', name: 'remark', type: 'textarea', span: 24
        }
      ],
      moduleName: 'rent_register'
    }
  },
  created() {
    getMmsTenantOptions().then(v => {
      this.queryFields = [
        {
          label: '租户简称',
          name: 'tenant',
          fieldType: 'select',
          queryType: '',
          options: v
        }
      ]
      this.formFields[0].options = v
      this.formFields[4].options = v
    })
  },
  methods: {
    moment,
    getMmsPage,
    createMmsInfo,
    updateMmsInfo,
    deleteMmsInfo
  }
}
</script>

<style>
  /* .rent .ant-form .ant-col-12{
    float: right;
  } */
</style>
